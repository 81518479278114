import React, { useEffect, useState, useContext } from 'react'
import { animated, useTransition } from '@react-spring/web'
import clsx from 'clsx'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { NavigationElement, useMobileHeaderQuery } from '@contentfulTypes'
import { MobileButtonBar } from './MobileButtonBar'
import { Icon } from '~/elements/Icon/Icon'
import { lockBodyScroll, freeBodyScroll } from '~/lib/scrollLock'
import { Image } from '~/elements/Image/Image'
import { Link } from '~/elements/Link/Link'
import { NextLevel } from './NextLevel'
import { BottomSection } from './BottomSection'
import { useRouter } from 'next/router'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { UIContext } from '~/lib/Context/UIStore'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { useTranslation } from 'next-i18next'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'

interface ListItemProps {
  item: NavigationElement
  naviLevel: string
  className?: string
}

export const ListItem: React.FC<ListItemProps> = ({ item, naviLevel, className = '' }) => {
  const { t } = useTranslation()

  const { dataLayer } = useGTM()

  const onNavItemClick = () => {
    dataLayer(DataLayerEnum.EVENT, 'nav_item_click', {
      dataLayer: {
        method: 'navigation',
        navigation_level: naviLevel,
        navigation_item: item?.title ? item?.title : item.subline,
      },
    })
  }

  return item?.pageContent?.slug ? (
    <Link href={`${item?.pageContent.slug as string}`} prefetch={false} className={className}>
      {item?.title ? (
        <>
          <div
            className={clsx('block', {
              'w-fit px-2 py-0.5 text-center': item?.highlightedColor,
              'bg-sg-neon-yellow': item?.highlightedColor === 'Yellow',
              'bg-[#BBDEDD]': item?.highlightedColor === 'Light-blue',
            })}
            onClick={onNavItemClick}
          >
            {item?.title}
          </div>
        </>
      ) : (
        <div
          className={clsx({
            'w-fit px-2 text-center': item?.highlightedColor,
            'bg-sg-neon-yellow': item?.highlightedColor === 'Yellow',
            'bg-[#BBDEDD]': item?.highlightedColor === 'Light-blue',
          })}
          onClick={onNavItemClick}
        >
          {item?.subline}
        </div>
      )}
    </Link>
  ) : (
    <>{item?.title}</>
  )
}

export const MobileFlyout = ({}) => {
  const { mobileFlyoutToggle, setMobileFlyoutToggle } = useContext(UIContext)
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useMobileHeaderQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })
  const [hasScrolled, setHasScrolled] = useState(false)
  const mobileNavData =
    data?.pageCollection?.items?.[0]?.mainMegaNavigation?.mobileFirstLevelItemsCollection?.items

  const slideY = useTransition(mobileFlyoutToggle, {
    from: { transform: 'translate3d(0,-100%,0)' },
    enter: { transform: 'translate3d(0,0%,0)' },
    leave: { transform: 'translate3d(0,-100%,0)' },
  })

  const handleToggleFlyout = () => {
    if (mobileFlyoutToggle) {
      setMobileFlyoutToggle?.(false)
    } else setMobileFlyoutToggle?.(true)
  }

  useEffect(() => {
    const closeFlyout = () => {
      setMobileFlyoutToggle?.(false)
    }
    router.events.on('routeChangeStart', closeFlyout)

    return () => {
      router.events.off('routeChangeStart', closeFlyout)
    }
  }, [setMobileFlyoutToggle, router])

  useEffect(() => {
    mobileFlyoutToggle ? lockBodyScroll() : freeBodyScroll()
  }, [mobileFlyoutToggle])

  if (
    !data ||
    data?.pageCollection?.items?.[0]?.mainMegaNavigation?.mobileFirstLevelItemsCollection?.items
      .length === 0
  )
    return null

  return (
    <>
      <button title="open-flyout" className="inline" onClick={handleToggleFlyout}>
        <Image
          src={'/images/Icons/stats.svg'}
          placeholder="empty"
          width={32}
          height={24}
          alt="stats-icon"
        />
      </button>
      {slideY(
        (style, i) =>
          i && (
            <animated.nav
              style={{ height: '100dvh', ...style }}
              className="fixed right-0 top-0 left-0 z-50 h-[100vh] overflow-x-hidden overflow-y-hidden bg-primary-white"
            >
              <button
                title="close-flyout"
                className="fixed top-6 right-5 z-30"
                onClick={handleToggleFlyout}
              >
                <Icon name="cross" />
              </button>
              <menu
                className="h-screen overflow-scroll [&_>_li:nth-last-child(2)]:pb-7"
                onScroll={(e: React.UIEvent<HTMLElement>) =>
                  setHasScrolled((e.target as Element).scrollTop !== 0)
                }
              >
                <li
                  className={clsx(
                    'sticky top-0 z-20 flex flex-row justify-between bg-primary-white p-5',
                    hasScrolled && 'shadow-[0px_0px_20px_rgba(0,0,0,0.15)]',
                  )}
                >
                  <MobileButtonBar />
                </li>
                {mobileNavData?.map((item, rootIndex: number) => {
                  if (!item) return null
                  const secondLevelData = item?.subElementsCollection?.items?.length
                    ? item?.subElementsCollection?.items
                    : null

                  const bottomElement = item?.bottomElement

                  return (
                    <li key={item?.sys.id}>
                      <div className="top-0 bg-primary-white px-5 py-2.5 text-[20px] font-medium uppercase leading-[20px] text-primary-black">
                        <ListItem item={item as NavigationElement} naviLevel={'1st_level'} />
                      </div>
                      {secondLevelData ? (
                        <menu className="px-8 pt-2 pb-[18px] [&_li:last-child]:pb-0">
                          {secondLevelData?.map((item, indexCol: number) => {
                            if (!item) return null
                            return item?.categoriesCollection?.items.map((item, index: number) => {
                              if (!item || !item.title) return null
                              const thirdLevelData = item?.subElementsCollection?.items.length
                                ? item?.subElementsCollection?.items
                                : null
                              const firstItemExpander =
                                index === 0 && indexCol === 0 && rootIndex === 0
                              return (
                                <li
                                  key={item?.sys?.id}
                                  className="flex w-full justify-between py-2.5 text-[18px] font-medium leading-[23px]"
                                >
                                  {thirdLevelData ? (
                                    <NextLevel
                                      data={item as NavigationElement}
                                      firstItemExpander={firstItemExpander}
                                    >
                                      <menu>
                                        {!firstItemExpander && (
                                          <li className="ml-[42px] mt-[26px] mb-[30px] lowercase first-letter:uppercase [&_div]:first-letter:uppercase">
                                            <ListItem
                                              item={item as NavigationElement}
                                              naviLevel={'3rd_level'}
                                            />
                                          </li>
                                        )}
                                        {thirdLevelData.map((item) => {
                                          if (!item || !item.title) return null
                                          return (
                                            <li
                                              className={clsx(
                                                firstItemExpander
                                                  ? 'mt-4 mb-2 pl-3.5'
                                                  : 'pl-[58px] pb-4 last:mb-[46px]',
                                              )}
                                              key={item?.sys.id}
                                            >
                                              <div
                                                className={clsx(
                                                  'text-[14px] font-extralight leading-[22px] text-primary-black',
                                                )}
                                              >
                                                <ListItem
                                                  item={item as NavigationElement}
                                                  naviLevel={'3rd_level'}
                                                />
                                              </div>
                                            </li>
                                          )
                                        })}
                                      </menu>
                                    </NextLevel>
                                  ) : (
                                    <ListItem
                                      item={item as NavigationElement}
                                      naviLevel={'2nd_level'}
                                    />
                                  )}
                                </li>
                              )
                            })
                          })}
                          {bottomElement && bottomElement.title ? (
                            <li className="py-2.5 text-[18px] font-medium leading-[23px]">
                              <ListItem
                                item={bottomElement as NavigationElement}
                                naviLevel={'2nd_level'}
                                className="underline decoration-1"
                              />
                            </li>
                          ) : null}
                        </menu>
                      ) : null}
                    </li>
                  )
                })}
                <BottomSection />
              </menu>
            </animated.nav>
          ),
      )}
    </>
  )
}
