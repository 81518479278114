import { BanderoleQuery } from '@contentfulTypes'
import { animated, useSpring } from '@react-spring/web'
import { useContext, useState } from 'react'
import { useMedia } from '~/hooks/useMedia'
import { UIContext } from '~/lib/Context/UIStore'
import { BanderoleNewsletter } from '~/modules/BanderoleNewsletter/BanderoleNewsletter'

type Props = {
  banderoleData?: BanderoleQuery
}

export const StickyNewsletter = ({ banderoleData }: Props) => {
  const banderole = banderoleData?.pageCollection?.items?.[0]?.secondaryBanderole

  const { desktopNaviState: state, searchToggle } = useContext(UIContext)
  const isDesktop = useMedia('lgx')
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false)

  const reveal = useSpring({
    opacity: isDesktop
      ? !state?.secondLevelToggle || searchToggle
        ? 1
        : state?.secondLevelToggle
        ? 0
        : 0
      : 1,
    zIndex: isDesktop
      ? !state?.secondLevelToggle || searchToggle
        ? 20
        : state?.secondLevelToggle
        ? -21
        : -21
      : 20,
  })

  if (!banderole) return null

  return (
    <animated.div style={reveal} className="relative">
      <div>
        <button
          className="sticky block h-[48px] w-full text-center font-extralight leading-[48px]"
          style={{
            background: banderole?.backgroundColor as string,
            color: banderole?.textColor as string,
          }}
          onClick={() => setShowNewsletterPopup(true)}
        >
          <svg
            className="mt-[-2px] hidden pr-[10px] md:inline"
            width="41"
            height="25"
            viewBox="0 0 41 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group">
              <path
                id="Vector"
                d="M16.9597 12.46C17.8597 12.46 18.7497 12.12 19.4397 11.44C20.0997 10.78 20.4697 9.9 20.4697 8.96C20.4697 8.03 20.1097 7.15 19.4397 6.49C18.0697 5.12 15.8597 5.13 14.4897 6.49C13.1297 7.85 13.1297 10.07 14.4897 11.44C15.1697 12.12 16.0697 12.46 16.9597 12.46ZM15.1997 7.2C15.6897 6.71 16.3297 6.47 16.9697 6.47C17.6097 6.47 18.2497 6.71 18.7397 7.2C19.2097 7.67 19.4697 8.3 19.4697 8.97C19.4697 9.64 19.2097 10.27 18.7397 10.74C17.7697 11.71 16.1797 11.71 15.1997 10.74C14.2197 9.76 14.2197 8.17 15.1997 7.2Z"
                style={{ fill: banderole?.textColor as string }}
              />
              <path
                id="Vector_2"
                d="M21.5597 13.56C20.1897 14.93 20.1897 17.14 21.5597 18.51C22.2397 19.19 23.1397 19.53 24.0297 19.53C24.9297 19.53 25.8197 19.19 26.5097 18.51C27.8697 17.14 27.8697 14.93 26.5097 13.56C25.1497 12.2 22.9297 12.2 21.5597 13.56ZM25.7997 14.27C26.7697 15.24 26.7697 16.83 25.7997 17.81C24.8297 18.78 23.2397 18.79 22.2597 17.81C21.2897 16.84 21.2897 15.25 22.2597 14.27C22.7497 13.78 23.3897 13.54 24.0297 13.54C24.6797 13.54 25.3197 13.78 25.7997 14.27Z"
                style={{ fill: banderole?.textColor as string }}
              />
              <path
                id="Vector_3"
                d="M15.8996 17.1C15.9996 17.2 16.1296 17.25 16.2496 17.25C16.3696 17.25 16.5096 17.2 16.5996 17.1L25.0796 8.62001C25.2796 8.42001 25.2796 8.11001 25.0796 7.91001C24.8796 7.71001 24.5696 7.71001 24.3696 7.91001L15.8896 16.39C15.7096 16.58 15.7096 16.9 15.8996 17.1Z"
                style={{ fill: banderole?.textColor as string }}
              />
              <path
                id="Vector_4"
                d="M40.5 0H5.5C5.22 0 5 0.22 5 0.5C5 2.98 2.98 5 0.5 5C0.22 5 0 5.22 0 5.5V19.5C0 19.78 0.22 20 0.5 20C2.98 20 5 22.02 5 24.5C5 24.78 5.22 25 5.5 25H40.5C40.78 25 41 24.78 41 24.5V0.5C41 0.22 40.78 0 40.5 0ZM40 24H5.98C5.74 21.37 3.64 19.26 1 19.02V5.98C3.63 5.74 5.74 3.64 5.98 1H40V24Z"
                style={{ fill: banderole?.textColor as string }}
              />
              <path
                id="Vector_5"
                d="M35.5 15.5C35.78 15.5 36 15.28 36 15V10C36 9.72 35.78 9.5 35.5 9.5C35.22 9.5 35 9.72 35 10V15C35 15.28 35.22 15.5 35.5 15.5Z"
                style={{ fill: banderole?.textColor as string }}
              />
            </g>
          </svg>
          <span
            className="text-p [&_*]:text-p"
            dangerouslySetInnerHTML={{
              __html: banderole?.banderoleContent?.newsletterText as string,
            }}
          />
        </button>
        <BanderoleNewsletter
          open={showNewsletterPopup}
          close={() => setShowNewsletterPopup(false)}
        />
      </div>
    </animated.div>
  )
}
