import { useMemo } from 'react'
import { ButtonStyle, ButtonTheme } from '~/elements/Button/ButtonMain'

type ButtonStyleSG =
  | 'Main-dark'
  | 'Main-light'
  | 'Secondary-dark'
  | 'Secondary-light'
  | 'Text-dark'
  | 'Text-light'
  | 'Text-small-dark'
  | 'Text-small-light'
  | 'Text-min-dark'
  | 'Text-min-light'
  | 'TextImgBig-dark'
  | 'TextImgBig-light'
  | 'BigText'
  | 'MediumText'
  | 'SmallText'

type ButtonProps = {
  variant: ButtonStyle
  theme: ButtonTheme
}

const styleMapping: Record<string, ButtonStyle> = {
  Main: 'Main',
  Secondary: 'Secondary',
  Text: 'Text',
  TextImgBig: 'Text',
  'Text-small': 'TextSmall',
  'Text-min': 'TextMin',
  BigText: 'Text',
  MediumText: 'TextSmall',
  SmallText: 'TextMin',
}

const isButtonStyleSG = (value: string): value is ButtonStyleSG => {
  return Object.keys(styleMapping).some((key) => value.startsWith(key))
}

export const useGetCtaType = (buttonStyle: string | null | undefined): ButtonProps => {
  return useMemo(() => {
    if (!buttonStyle || !isButtonStyleSG(buttonStyle)) {
      return { variant: 'Main', theme: 'dark' }
    }

    const [style, theme] = buttonStyle.split('-') as [string, ButtonTheme]

    const variant = styleMapping[style] || 'Main'

    return { variant, theme }
  }, [buttonStyle])
}
