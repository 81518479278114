//SearchFlyoutDesktop in Search root component folder because it is rendered in ThirdLevel nav component
import { useRef, useEffect, useState } from 'react'
import { useMedia } from 'hooks/useMedia'
import { ResultsView } from './components/ResultsView'
import { StartView } from './components/StartView'
import useLocalStorage from 'hooks/useLocalStorage'

type Props = {
  searchValue: string
}

export const SearchFlyoutDesktop = ({ searchValue }: Props) => {
  const searchOverlayRef = useRef<HTMLDivElement>(null)
  const is2XLDesktop = useMedia('2xl')
  const [recentlyViewedIds] = useLocalStorage('recentlyViewedProducts', '')
  const [shownProducts, setShownProducts] = useState<null | number>(null)
  useEffect(() => {
    if (window) {
      setShownProducts(is2XLDesktop ? 12 : 8)
    }
  }, [is2XLDesktop])

  if (shownProducts) {
    return (
      <div ref={searchOverlayRef} id="flyout" className={'col-span-4 mt-20'}>
        <div className="relative flex bg-primary-white" data-cnstrc-autosuggest>
          {!searchValue && !searchValue?.replace(/\s/g, '').length ? (
            <>
              <StartView
                numberOfProductsShown={shownProducts}
                recentlyViewedIds={recentlyViewedIds}
              />
            </>
          ) : (
            <ResultsView searchValue={searchValue} numberOfProductsShown={shownProducts} />
          )}
        </div>
      </div>
    )
  }

  return null
}
