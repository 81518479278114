import { useMemo } from 'react'
import { StageQuery, Cta } from '@contentfulTypes'
import { isExternal } from '~/lib/externalLink'

export type CtaData = NonNullable<NonNullable<StageQuery['stage']>['cta']> | Cta

export const useGetCtaHref = (cta: CtaData): string | null => {
  return useMemo(() => {
    const {
      linkInternalUrl,
      linkExternalPage,
      linkExistingPage,
      phoneLink,
      eMailLink,
      linkToEntry,
    } = cta

    if (linkInternalUrl) {
      return linkInternalUrl
    }

    if (linkExternalPage && isExternal(linkExternalPage)) {
      return linkExternalPage
    }

    if (linkExistingPage?.slug && !isExternal(linkExistingPage.slug) && !linkToEntry?.sys?.id) {
      return linkExistingPage.slug
    }

    if (phoneLink && !linkExistingPage?.slug && !linkExternalPage) {
      return `${phoneLink}`
    }

    if (eMailLink && !linkExistingPage?.slug && !linkExternalPage) {
      return `${eMailLink}`
    }

    if (linkToEntry?.sys?.id && !linkExistingPage?.slug) {
      return `#${linkToEntry.sys.id}`
    }

    if (linkToEntry?.sys?.id && linkExistingPage?.slug) {
      return `${linkExistingPage.slug}/#${linkToEntry.sys.id}`
    }

    return null
  }, [cta])
}
