import { useGetCtaType } from '~/hooks/useGetCtaType'
import { useGetCtaHref, CtaData } from '~/hooks/useGetCtaHref'
import { ButtonMain, Props } from '~/elements/Button/ButtonMain'

type ButtonCtaProps = Omit<Props, 'variant' | 'theme' | 'href'> & {
  cta: CtaData
}

export const ButtonCta: React.FC<ButtonCtaProps> = ({ cta, children, ...props }) => {
  const { variant, theme } = useGetCtaType(cta.ctaStyle || cta.newCtaStyle)
  const href = useGetCtaHref(cta)

  return (
    <ButtonMain variant={variant} theme={theme} href={href ?? undefined} {...props}>
      {cta.ctaText || children}
    </ButtonMain>
  )
}
